@font-face {
font-family: '__domaineDisplay_ad0bd1';
src: url(/_next/static/media/90f6dfcb8d4ae221-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}@font-face {font-family: '__domaineDisplay_Fallback_ad0bd1';src: local("Arial");ascent-override: 102.51%;descent-override: 27.87%;line-gap-override: 0.00%;size-adjust: 100.47%
}.__className_ad0bd1 {font-family: '__domaineDisplay_ad0bd1', '__domaineDisplay_Fallback_ad0bd1';font-weight: normal;font-style: normal
}.__variable_ad0bd1 {--font-domaine-display: '__domaineDisplay_ad0bd1', '__domaineDisplay_Fallback_ad0bd1'
}

/* vietnamese */
@font-face {
  font-family: '__Work_Sans_62958f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/0822b6067e7c0dee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Work_Sans_62958f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/67354d9f27664b22-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Work_Sans_62958f';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6905431624c34d00-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Work_Sans_62958f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0822b6067e7c0dee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Work_Sans_62958f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/67354d9f27664b22-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Work_Sans_62958f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6905431624c34d00-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Work_Sans_62958f';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0822b6067e7c0dee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Work_Sans_62958f';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/67354d9f27664b22-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Work_Sans_62958f';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6905431624c34d00-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Work_Sans_Fallback_62958f';src: local("Arial");ascent-override: 83.09%;descent-override: 21.71%;line-gap-override: 0.00%;size-adjust: 111.93%
}.__className_62958f {font-family: '__Work_Sans_62958f', '__Work_Sans_Fallback_62958f';font-style: normal
}.__variable_62958f {--font-work-sans: '__Work_Sans_62958f', '__Work_Sans_Fallback_62958f'
}

:root {
  --color-background_white: #FFF;
  --color-background_login: #E4E3DC;
  --color-navbar: #014E98;
  --color-text: #323232;
  --color-textSecondary: #302E3B;
  --color-white: #FFF;
  --color-whiteOpaque: #FAF9F8;
  --color-white_d8: #D8D8D8;
  --color-blue: #0374E0;
  --color-blue_link: #0073E8;
  --color-yellow: #FFCA00;
  --color-green: #70D02E;
  --color-red: #EB005B;
  --color-grey: #D7D7D7;
  --color-brown: #918376;
  --color-input_base: #0374E0;
  --color-input_error: #EB005B;
  --color-input_black: #000;

  --color-darkgreen: #274943;
  --color-beige: #F9F2EC;
  --color-background: #F9F2EC;
}

html {
  -moz-osx-font-smoothing     : grayscale;
  -webkit-font-smoothing      : antialiased;
  -webkit-tap-highlight-color : transparent;
  box-sizing: border-box;
  /* This variable is setup in the /app/layout.tsx file */
  font-family: var(--font-domaine-display), sans-serif;
  font-size: 100%;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  min-height: 100%;
}

html, body,
nav, header, main, footer,
section, aside, article, div,
img, caption, figure, figcaption, video, audio, progress,
h1, h2, h3, h4, h5, h6, hgroup
p, span, small, strong,
ol, ul, li,
dl, dt, dd,
blockquote,
form, fieldset, legend, label,
table, tbody, tfoot, thead, tr, th, td,
pre, abbr, address, cite, summary, code,
del, dfn, em, ins, kbd, q, samp, sub, sup, var, time, mark {
  background: transparent;
  border: 0;
  direction: ltr;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
  position: relative;
  scroll-behavior: smooth;
}

html,
body {
  font-size: 3.6231884057971016vw;
}

@media (min-width: 603px) {
  html,
  body {
    font-size: 2.487562189054726vw;
  }
}

@media (min-width: 768px) {
  html,
  body {
    font-size: 1.25vw;
  }
}

@media (min-width: 1440px) {
  html,
  body {
    font-size: 18px;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

.srt-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  font-family: inherit;
  padding: 0;
}

button,
a {
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  outline: 0;
}

a {
  text-decoration: none;
}

a:active,
a:hover,
a:focus,
button:active,
button:hover,
button:focus {
  outline: 0;
}

p,
span,
small,
strong {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  text-align: center;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

li {
  list-style-type: none;
}

img {
  -ms-interpolation-mode: bicubic;
  transform: translate3d(0, 0, 0);
  vertical-align : middle;
  width: 100%;
}

figure,
figure img {
  margin: 0;
}

input:active,
textarea:active,
input:focus,
textarea:focus,
input:hover,
textarea:hover {
  outline: 0;
}

textarea {
  font-family: inherit;
}

input {
  background-color: var(--color-background);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  margin-bottom: 0;
}

input::-webkit-caps-lock-indicator,
input::-webkit-credentials-auto-fill-button,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button {
  display: none;
  position: absolute;
  right: 0;
  visibility: hidden;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

input:-webkit-autofill {
  content: '\feff';
}

h1, h2, h3, h4, h5, h6 {
  text-rendering: optimizelegibility;
  font-weight: 400;
}

p {
  color: inherit;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
}

small {
  font-size: 12px;
}

ol,
ul,
dl,
dd,
address {
  margin-bottom : 0;
}

address {
  font-style: normal;
}

dl {
  outline: 0;
}

dt {
  font-weight: bold;
}

blockquote  {
  border-left: 10px solid var(--color-text);
  font-style: normal;
  margin: 0;
  padding: 0 15px;
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted var(--color-text);
}

b,
strong {
  font-weight : bold;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

sup {
  right: .2em;
  top: .2em;
}

sub {
  bottom: -.25em;
}

body.slider-being-dragged .flickity-viewport a,
body.slider-being-dragged .flickity-viewport button {
  pointer-events: none !important;
}

.slider--flickify {
  outline: none;
}


.flickity-enabled:focus { outline: none; }

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.global-err {
  align-items: center;
  background-color: var(--color-light-grey);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  justify-content: center;
  margin: 0 auto;
  overflow: auto;
  width: 100vw;
}

@media (min-width: 768px) {
  .global-err {
    height: 100vh;
  }
}

.global-err hgroup {
  margin: 180px auto;
  max-width: 80vw;
}

.global-err h1 {
  margin-bottom: 20px;
  font-family: var(--font-graphik);
  font-size: 20px;
  font-weight: normal;
  line-height: 28px;
  letter-spacing: 0;
}

.global-err p,
.global-err button,
.global-err a {
  font-size: 30px;
  line-height: 32px;
  font-weight: 400;
}

.global-err a {
  text-decoration: underline;
}

